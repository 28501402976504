import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
// import ReactDOM from "react-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClone } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              The <span className="text-color-primary">Exchange </span>
              that comes to you
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                A white label solution that helps WEB3 projects embed their own
                exchange.
              </p>
              <a
                data-video="
              https://www.youtube.com/embed/j-lisnfNR6U"
                href="#0"
                aria-controls="video-modal"
                onClick={openModal}
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/video-placeholder2bb.png")}
                  alt="Converter"
                  // width={448}
                  // height={252}
                />
              </a>
              <br></br>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button
                    target="_blank"
                    tag="a"
                    color="primary"
                    wideMobile
                    href="mailto:contact@2bb.io?subject=Add%20Exchange"
                  >
                    contact@2bb.io
                    {/* <FontAwesomeIcon icon={faClone} color={`#ccc`} /> */}
                  </Button>
                  <Button
                    target="_blank"
                    tag="a"
                    color="dark"
                    wideMobile
                    href="https://t.me/company_2bb"
                  >
                    Telegram
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div id="converter"></div>
              <div id="palette"></div>
            </div>
          </div>

          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="
            https://www.youtube.com/embed/j-lisnfNR6U"
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
